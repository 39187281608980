
import { mapState, mapMutations } from 'vuex'

export default {
  mounted() {
    this.$nextTick(() => {
      const scriptUrl = 'https://letzchat.pro/firestoreconfig/plugin/letzchat.js'
      let script = document.querySelector(`script[src="${scriptUrl}"]`)
      
      if (!script) {
        // Create and add the script if it doesn't exist
        script = document.createElement('script')
        script.src = scriptUrl
        script.async = true
        document.head.appendChild(script)

        // Check for variable after script loads
        script.onload = () => {
          console.log('Script loaded successfully')
          this.checkLzLanguage()
        }

        // Error handling if the script fails to load
        script.onerror = () => {
          console.error('Failed to load the LetzChat script')
        }
      } else {
        // Start polling if script is already present
        this.checkLzLanguage()
      }
    })
  },
  methods: {
    ...mapMutations(['setLanguage']),
    switchLanguage(code) {
      if (typeof lzSwitchLanguage !== 'undefined') {
        lzSwitchLanguage(code) // Access lzSwitchLanguage directly without window
      }
      this.setLanguage(code)
    },
    checkLzLanguage() {
      // Poll every 100ms to check if lzCurrentLanguage is defined
      const interval = setInterval(() => {
        try {
          console.log('Checking for lzCurrentLanguage...')
          if (typeof lzCurrentLanguage !== 'undefined') {
            console.log('lzCurrentLanguage is now available:', lzCurrentLanguage)
            this.setLanguage(lzCurrentLanguage)
            clearInterval(interval) // Stop polling once available
          }
        } catch (error) {
          console.error('Error accessing lzCurrentLanguage:', error)
        }
      }, 100)
    }
  },
  computed: {
    ...mapState(['language']),
  }
}
