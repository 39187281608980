
  import { mapState, mapMutations, mapActions } from 'vuex'

  export default {
    mounted() {
      document.querySelector('body').style.overflowY = 'hidden'
    },
    destroyed() {
      // Remove style tag from body or set to overflow auto
      const body = document.querySelector('body')
      const styleText = body.textContent.trim()
      const cssRules = styleText.split(';')
      if (cssRules.length === 1 && cssRules[0].trim().startsWith('overflow-y')) {
        body.removeAttribute('style')
      } else {
        body.style.overflowY = 'auto'
      }
    },
    computed: {
      ...mapState(['user','location','order', 'channel']),
    },
    methods: {
      goToAccount() {
        this.$router.push('/account')
      }
    },
    watch: {
      '$route.path'(e) {
        this.$store.commit('setShowMobileMenu', false)
      }
    }
  }
